import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Show,
  TabbedShowLayout,
  DateField,
  BooleanField,
  EmailField,
  ShowButton,
  Tab,
  Filter,
  SearchInput,
  NullableBooleanInput,
  ChipField,
  BooleanInput,
  TextInput,
  DeleteWithConfirmButton,
} from "react-admin";
import FullNameField from "../components/FullNameField";

const UsersFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="name" alwaysOn placeholder="Search by Name" />
    <SearchInput source="email" placeholder="Search by email id" />
    <SearchInput source="phoneNumber" placeholder="Search by phone number" />

    {/* User Type Fields */}
    <BooleanInput source="isVolunteer" label="Volunteer" />
    <BooleanInput source="isDonor" label="Donor" />
    <BooleanInput source="isCompanyRep" label="CompanyRep" />
    <BooleanInput source="isFreelancer" label="Free Lancer" />

    {/* Voluteer fields */}
    <TextInput
      source="volunteer.interests"
      label="Volunteer Interests"
      placeholder="Search by volunteer interests"
    />
    <TextInput
      source="volunteer.volunteerSkillSet"
      label="Volunteer skillSet"
      placeholder="Search by volunteer skill set"
    />
    <TextInput
      source="volunteer.languages"
      label="Volunteer languages"
      placeholder="Search by volunteer languages"
    />
    <TextInput
      source="volunteer.availability"
      label="Volunteer Availability"
      placeholder="Search by volunteer availability"
    />
    <TextInput
      source="volunteer.volunteerMotivation"
      label="Volunteer Motivation"
      placeholder="Search by volunteer motivation"
    />
    <TextInput
      source="volunteer.professionalSkills"
      label="Volunteer professional skills"
      placeholder="Search by volunteer professional skills"
    />
    <TextInput
      source="volunteer.otherSkills"
      label="Volunteer other skills"
      placeholder="Search by volunteer other skills"
    />

    {/* Free Lancer */}
    <TextInput
      source="freelancer.technicalExpertise"
      label="Freelancer technical expertise"
      placeholder="Search by freelancer technical expertise"
    />
    <TextInput
      source="freelancer.computerSkills"
      label="Freelancer computer skills"
      placeholder="Search by freelancer computer skills"
    />
    <TextInput
      source="freelancer.languages"
      label="Freelancer languages"
      placeholder="Search by freelancer languages"
    />
    <TextInput
      source="freelancer.microsoftOffice"
      label="Freelancer microsoft Office skills"
      placeholder="Search by freelancer microsoft Office skills"
    />
    <TextInput
      source="freelancer.analyticalSkills"
      label="Freelancer analytical Skills"
      placeholder="Search by freelancer analytical Skills"
    />
    <TextInput
      source="freelancer.professionalSkills"
      label="Freelancer professional skills"
      placeholder="Search by freelancer professional skills"
    />
    <TextInput
      source="freelancer.marketing"
      label="Freelancer marketing skills"
      placeholder="Search by freelancer marketing skills"
    />
    <TextInput
      source="freelancer.otherSkills"
      label="Freelancer other skills"
      placeholder="Search by freelancer other skills"
    />
    <TextInput
      source="freelancer.design"
      label="Freelancer Design"
      placeholder="Search by freelancer designs"
    />
    <TextInput
      source="freelancer.certifications"
      label="Freelancer Certifications"
      placeholder="Search by freelancer Certifications"
    />
    <TextInput
      source="freelancer.projectManagement"
      label="Freelancer project management"
      placeholder="Search by freelancer project Management"
    />
    <TextInput
      source="freelancer.writingSkills"
      label="Freelancer writing skills"
      placeholder="Search by freelancer writing skills"
    />

    {/* Donor fields */}
    <TextInput
      source="donor.donationType"
      label="Donor donation  Type"
      placeholder="Search by donor donation type"
    />
    <TextInput
      source="donor.availableRegions"
      label="Donor available regions"
      placeholder="Search by donor available regions"
    />
    <TextInput
      source="donor.bloodGroup"
      label="Donor Blood group"
      placeholder="Search by donor blood group"
    />

    {/* Company fields */}
    <TextInput
      source="company.socialCause"
      label="Compay social cause"
      placeholder="Search by company Social cause"
    />
  </Filter>
);

export const ListUsers = (props) => {
  return (
    <List bulkActionButtons={false} {...props} filters={<UsersFilter />}>
      <Datagrid optimized>
        <FullNameField source="name" />

        {/* <TextField label="Name" source="name" /> */}
        <EmailField label="E-mail" source="email" />
        <TextField label="Phone no." source="phoneNumber" />

        <BooleanField label="Volunteer" source="isVolunteer" />
        <BooleanField label="Company" source="isCompanyRep" />
        <BooleanField label="Donor" source="isDonor" />
        <BooleanField label="Verified" source="isVerified" />

        <DateField label="DOB" source="dob" />
        <DateField label="Joined On" source="createdAt" />

        <DeleteWithConfirmButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
