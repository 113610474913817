import React from "react";
import "./App.css";

import { Admin, Resource, ListGuesser, ShowGuesser } from "react-admin";
import { authClient, restClient } from "ra-data-feathers";

import feathersClient from "./feathersClient";

import { ListUsers } from "./services/users";
import { ShowUsers } from "./components/ShowUser";
const authClientOptions = {
  storageKey: "feathers-jwt",
  authenticate: { strategy: "local" },
  permissionsField: "role", // Use the 'userroles' field on the JWT as the users role
  redirectTo: "/login",
  logoutOnForbidden: true, // Logout when response status code is 403
};

// to rename id field for *all* resources use this syntax:
const options = {
  id: "_id",
};

function App() {
  return (
    <Admin
      authProvider={authClient(feathersClient, authClientOptions)}
      dataProvider={restClient(feathersClient, options)}
    >
      <Resource name="users" list={ListUsers} show={ShowUsers} />
      {/* <Resource name="posts" list={ListGuesser} show={ShowGuesser} /> */}
    </Admin>
  );
}

export default App;
