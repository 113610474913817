import feathers from "@feathersjs/client";

const host =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3030"
    : "https://api.thesocialtown.in";

export default feathers()
  .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
  .configure(
    feathers.authentication({
      jwtStrategy: "jwt",
      storage: window.localStorage,
    })
  );

// import io from "socket.io-client";
// import feathers from "@feathersjs/client";
// // import { toast } from "react-toastify";

// // The host url here
// export const host = process.env.NODE_ENV === "development" ? "localhost:3030" : "https://api.thesocialtown.in";
// export const REST_ENDPOINT =
//   process.env.NODE_ENV === "development" ? "http://localhost:3030" : "https://api.thesocialtown.in";

// const socket = io(host, { path: process.env.NODE_ENV === "development" ? "" : "/socket.io" });
// const client = feathers();

// client.configure(
//   feathers.socketio(socket, {
//     timeout: 15000,
//   })
// );
// client.configure(
//   feathers.authentication({
//     storage: window.localStorage,
//   })
// );
// client.reAuthenticate().catch((error) => {
//   // console.log("error", error);
//   localStorage.clear();
//     console.log(error)
//   if (error.message !== "No accessToken found in storage") {
//     // toast.error(`${error.name}:${error.message}`, {
//     //   position: "top-right",
//     //   draggable: true,
//     // });
//   }
// });
// client.on("authenticated", (login) => {
//   client
//     .service("users")
//     .find()
//     .then((user) => localStorage.setItem("user", JSON.stringify(user.data[0])))
//     .catch((err) => console.log("===>>", err));
// });
// export default client;
