import React from "react";
import {
  TextField,
  Show,
  TabbedShowLayout,
  DateField,
  BooleanField,
  EmailField,
  Tab,
  Labeled,
  UrlField,
  ImageField,
  DeleteWithConfirmButton,
  TopToolbar,
} from "react-admin";
import Chip from "@material-ui/core/Chip";

const VolunteerSkillSetTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Skill set"></Labeled>
    <div>
      {volunteer.volunteerSkillSet
        ? volunteer.volunteerSkillSet.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerAvailableRegionsTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Available Regions"></Labeled>
    <div>
      {volunteer.availableRegions
        ? volunteer.availableRegions.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);
const DonorAvailableRegionsTagsField = ({ record: { donor } }) => (
  <div>
    <Labeled source="Available Regions"></Labeled>
    <div>
      {donor.availableRegions
        ? donor.availableRegions.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerInterestsTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Interests"></Labeled>
    <div>
      {volunteer.interests
        ? volunteer.interests.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerProfessionalSkillsTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Professional Skills"></Labeled>
    <div>
      {volunteer.professionalSkills
        ? volunteer.professionalSkills.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerLanguagesTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Languages"></Labeled>
    <div>
      {volunteer.volunteer
        ? volunteer.languages.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);
const VolunteerOtherSkillsTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Other Skills"></Labeled>
    <div>
      {volunteer.otherSkills
        ? volunteer.otherSkills.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerCertificationsTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Certifications"></Labeled>
    <div>
      {volunteer.certifications
        ? volunteer.certifications.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);
//  Freelancer
const FreelancerProfessionalSkillsTagsField = ({ record: { freelancer } }) => (
  <div>
    <Labeled source="Professional Skills"></Labeled>
    <div>
      {freelancer.professionalSkills
        ? freelancer.professionalSkills.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const FreelancerCertificationsTagsField = ({ record: { freelancer } }) => (
  <div>
    <Labeled source="Certifications"></Labeled>
    <div>
      {freelancer.certifications
        ? freelancer.certifications.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const FreelancerWorkExperienceField = ({ record: { freelancer } }) => (
  <div>
    <Labeled source="Work Experience"></Labeled>
    <div>
      {freelancer.workExperience
        ? freelancer.workExperience.map((item, i) => (
            <div key={i}>
              {i + 1}.<div>Designation: {item.designation}</div>
              <div>Company Name: {item.companyName}</div>
              <div>Years of Experience: {item.yearsOfExperience}</div>
            </div>
          ))
        : "None"}
    </div>
  </div>
);

const FreelancerOtherSkillsTagsField = ({ record: { freelancer } }) => (
  <div>
    <Labeled source="Other Skills"></Labeled>
    <div>
      {freelancer.otherSkills
        ? freelancer.otherSkills.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

// Donor fields
const DonorDonationTypeTagsField = ({ record: { donor } }) => (
  <div>
    <Labeled source="Donation Type"></Labeled>
    <div>
      {donor.donationType
        ? donor.donationType.map((item) => (
            <Chip size="small" key={item} label={item} />
          ))
        : "None"}
    </div>
  </div>
);

const VolunteerAvailableTimeTagsField = ({ record: { volunteer } }) => (
  <div>
    <Labeled source="Available times"></Labeled>
    <div>
      {Object.keys(volunteer.availableTime).map((day, index) => (
        <div key={index}>
          {volunteer.availableTime[day].isAvailable && (
            <div>
              {day}: {volunteer.availableTime[day].startTime} -{" "}
              {volunteer.availableTime[day].endTime}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    {data && typeof data.id !== "undefined" && (
      <DeleteWithConfirmButton
        basePath={basePath}
        record={data}
        resource={resource}
      />
    )}
  </TopToolbar>
);

export const ShowUsers = (props) => (
  <Show actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Basic Details" className="flex">
        <TextField label="Name" source="name" />
        <TextField label="E-mail" source="email" />
        <TextField label="Phone no." source="phoneNumber" />
        <TextField label="Gender" source="gender" />
        <TextField label="About" source="about" />
        <BooleanField source="isVerified" />
        <UrlField target="blank" source="linkedinUrl" label="Linkedin Url" />
        <ImageField source="imageUrls.profileImage"></ImageField>
        <ImageField source="imageUrls.bannerImage"></ImageField>
        <TextField source="id" />
      </Tab>

      <Tab label="Address" path="address">
        <TextField label="Area" source="address.area" />
        <TextField label="city" source="address.city" />
        <TextField label="country" source="address.country" />
        <TextField label="pincode" source="address.pincode" />
      </Tab>
      <Tab label="Volunteer" path="volunteer">
        <BooleanField label="Volunteer" source="isVolunteer" />
        <TextField
          label="Available Hours Per Week"
          source="volunteer.availableHoursPerWeek"
        />
        <TextField
          label="Per Hour Remuneration"
          source="volunteer.perHourRemuneration"
        />

        <TextField label="Motivation" source="volunteer.volunteerMotivation" />
        <TextField label="Availablity" source="volunteer.availablity" />
        <VolunteerSkillSetTagsField source="volunteer" />
        <VolunteerAvailableRegionsTagsField source="volunteer" />
        <VolunteerAvailableTimeTagsField source="volunteer" />
        <VolunteerInterestsTagsField source="volunteer" />
        <VolunteerProfessionalSkillsTagsField source="volunteer" />
        <VolunteerLanguagesTagsField source="volunteer" />
        <VolunteerOtherSkillsTagsField source="volunteer" />
        <VolunteerCertificationsTagsField source="volunteer" />
      </Tab>

      <Tab label="Donor" path="donor">
        <TextField source="donor.bloodGroup" label="Blood Group" />

        <DonorDonationTypeTagsField source="donor" />
        <DonorAvailableRegionsTagsField source="donor" />
      </Tab>

      <Tab label="Company" path="company">
        <TextField source="company.companyName" label="Name" />
        <EmailField source="company.companyEmail" label="Email" />
        <TextField source="company.companyPhone" label="Phone" />
        <TextField source="company.companyAbout" label="About" />
        <DateField source="company.foundedYear" label="Founded Year" />
        <TextField source="company.workEmail" label="Work Email" />
        <UrlField
          target="blank"
          source="company.employeeIdCardUrl"
          label="Employee Id Card"
        />
        <UrlField
          target="blank"
          source="company.letterHeadUrl"
          label="Letter head"
        />
      </Tab>
      <Tab label="Freelancer" path="freelancer">
        <FreelancerWorkExperienceField source="freelancerr"></FreelancerWorkExperienceField>
        <FreelancerCertificationsTagsField source="freelancer" />
        <FreelancerProfessionalSkillsTagsField source="freelancer" />
        <FreelancerOtherSkillsTagsField source="freelancer" />

        <TextField
          label="Available Hours Per Week"
          source="freelancer.availableHoursPerWeek"
        />
        <TextField
          label="Per Hour Remuneration"
          source="freelancer.perHourRemuneration"
        />
      </Tab>
      <Tab label="User Type" path="userTypes">
        <BooleanField label="Volunteer" source="isVolunteer" />
        <BooleanField label="Company" source="isCompanyRep" />
        <BooleanField label="Donor" source="isDonor" />
        <BooleanField label="Freelancer" source="isFreelancer" />
      </Tab>
      <Tab label="Education" path="education">
        <TextField source="educationLevel" label="Education Level" />
        <TextField source="educationDetails" label="Education Details" />
      </Tab>
      <Tab label="Employment" path="employment">
        <TextField source="employmentLevel" label="Employment Level" />
        <TextField source="employmentDetails" label="Employment Details" />
      </Tab>
      <Tab label="Dates" path="dates">
        <DateField label="DOB" source="dob" />
        <DateField label="Joined On" source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
